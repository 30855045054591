import React from "react"
import Img from "gatsby-image"

function Image({ image = "", fluid = false, fixed = false }) {
  return (
    <>
      {fixed && <Img fixed={image.childImageSharp.fixed} />}
      {fluid && <Img fluid={image.childImageSharp.fluid} />}
    </>
  )
}
export {Image}