import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { Image } from "./helpers"
import { makeStyles, Grid, Box, Typography } from "@material-ui/core"

import AuthorImage from "../../static/images/partners/author.png"

const useStyles = makeStyles(theme => ({
  AuthorItem: {
    margin: "0 auto",
    textAlign: "center",
    outlineWidth: 0,
    marginBottom: "1.5rem",
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
      textAlign: "center",
      textDecoration: "none",
    },
    "& img": {
      textAlign: "center",
      maxWidth: 200,
    },
    "& p": {
      margin: "-5px auto 0",
      fontSize: "1rem",
      color: "#000",
      padding: "10px 0",
      maxWidth: 200,
    },
    "&& .AuthorItemBox p": {
      backgroundColor: "#e9e9e9",
    },
  },
  AuthorItemBox: {
    borderRadius: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
}))

const SingleAuthor = ({ authors }) => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  // const lastName = authors.lastName ? authors.lastName : " "

  const firstNameForLang =
    i18n.language === "ar"
      ? authors.firstName_ar
      : i18n.language === "tr"
      ? authors.firstName_tr
      : i18n.language === "en" && authors.firstName

  const lastNameForLang =
    i18n.language === "ar"
      ? authors.lastName_ar
      : i18n.language === "tr"
      ? authors.lastName_tr
      : i18n.language === "en" && authors.lastName

  const firstNameCheck = firstNameForLang ? firstNameForLang : " "
  const lastNameCheck = lastNameForLang ? lastNameForLang : " "

  return (
    <Grid item xs={12} sm={6} lg={3} md={3} className={classes.AuthorItem}>
      {/* slug */}
      <Link to={`/${i18n.language}/authors/${authors.strapiId}`}>
        <Box className={`${classes.AuthorItemBox} AuthorItemBox `}>
          {/* <img
            p={3}
            src={authors.image ? authors.image.childImageSharp.fixed.srcWebp : AuthorImage}
            alt={authors.firstName}
          /> */}
          {/* childImageSharp.fixed.srcWebp */}
          {authors.image && authors.image.childImageSharp ? (
            // <img src={authors.image} />
            <Image image={authors.image} fixed={true} /* fluid={true} */ />
          ) : (
            <img
              p={3}
              src={authors.image ? authors.image.publicURL : AuthorImage}
              alt={authors.firstName}
            />
          )}
          <Typography variant="h5" component="p">
            {firstNameCheck + " " + lastNameCheck}
          </Typography>
        </Box>
      </Link>
    </Grid>
  )
}
export default SingleAuthor
