import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import { Container, makeStyles } from "@material-ui/core"

import SEO from "../components/seo"
import AuthorList from "../components/author-list"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

// import CategoryBg from "../../static/images/mainBg.jpg"
import AuthorsImage from "../../static/images/publishers/books.svg"

const useStyles = makeStyles(theme => ({
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  AuthorsBg: {
    // backgroundImage: `url(${CategoryBg})`,
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    margin: 0,
  },
}))

const Authors = ({ data }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()

  const breadcrumbs = [
    {
      text: t("breadcrumbs.authors"),
    },
  ]

  const authors = []
  for (let author of data.allStrapiAuthors.nodes) {
    let objCopy = Object.assign({}, author)
    authors.push(objCopy)
  }

  return (
    <>
      <SEO title="authors" lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("breadcrumbs.authors")} image={AuthorsImage} />
      <Container className={`${classes.fullWidth} ${classes.AuthorsBg}`}>
        <AuthorList authors={authors} />
      </Container>
    </>
  )
}
export default Authors

export const query = graphql`
  query Authors {
    allStrapiAuthors {
      nodes {
        firstName
        firstName_ar
        firstName_tr
        lastName
        lastName_ar
        lastName_tr
        slug
        strapiId
        description
        image {
          publicURL
        }
      }
    }
  }
`
